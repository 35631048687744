import { AuthenticationModel } from '@nstep-common/core';
import { ExtraValidator } from '@nstep-common/utils';

export class LoginModelValidator extends ExtraValidator<AuthenticationModel> {
	constructor() {
		super();

		this.ruleFor('userName')
			.must(userName => !!userName)
			.withMessage('The Amis Card Number field is required.')
			.matches(new RegExp('^([A-Za-z]{3})(-[0-9]{7})(-[0-9]{2})$'))
			.withMessage('You must specify a valid Amis Card Number.');

		this.ruleFor('password')
			.must(password => !!password)
			.withMessage('The Pin field is required.')
			.matches(new RegExp('^([0-9]{4})$'))
			.withMessage('You must specify a valid PIN.');
	}
}

export class LoginOperatorModelValidator extends ExtraValidator<AuthenticationModel> {
	constructor() {
		super();

		this.ruleFor('userName')
			.must(userName => !!userName)
			.withMessage('The User name field is required.');

		this.ruleFor('password')
			.must(password => !!password)
			.withMessage('The Password field is required.');
	}
}