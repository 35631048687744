export class PageItem {
	header: any | null = null;
	items: PageItem[] = [];
}

export class TreeNode {
	key: string | null = null;
	collapsed = true;
	level = 0;
	data: any | null = null;
	children: TreeNode[] = [];
}

export class ColumnSortation {
	key: string = '';
	sortAsc: boolean | null = null;

	constructor(data: Partial<ColumnSortation> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}