import { JsonProperty } from '@nstep-common/utils';

export class HeadquarterModel {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;
}

export class POSLocationModel {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	informationId!: number;

	@JsonProperty({ type: String })
	name!: string;

	@JsonProperty({ type: Boolean })
	enabled!: boolean;

	@JsonProperty({ type: HeadquarterModel })
	headquarter!: HeadquarterModel;
}

export class CardExpirationDetailsDto {
	@JsonProperty({ type: String })
	cardNumber!: string;

	@JsonProperty({ type: String })
	expirationDate!: string;

	@JsonProperty({ type: Number })
	expirationDays!: number;
}

export class OperatorExpirationDetailsDto {
	@JsonProperty({ type: String })
	expirationDate!: string;

	@JsonProperty({ type: Number })
	expirationDays!: number;
}

export class ExtendedPOSLocationDto {
	@JsonProperty({ type: POSLocationModel })
	location!: POSLocationModel;

	@JsonProperty({ type: CardExpirationDetailsDto })
	card!: CardExpirationDetailsDto;

	@JsonProperty({ type: OperatorExpirationDetailsDto })
	operator!: OperatorExpirationDetailsDto;
}
