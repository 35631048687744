<div class="ui centered grid">
	<div class="center aligned column" app-loading [enabled]="loading" style="width: 450px; margin-top: 15%">
		<div class="ui segment">
			<img src="assets/images/NSTEP-logo.png" style="width: 170px;">
			<div class="ui form">

				<h5>POS Locations:</h5>
				<div class="field">
					<sm-dropdown class="ui fluid selection dropdown label"
						[(value)]="selectedPosLocationId"
						[options]="posLocationsDropdownValues"
						(valueChange)="onPosLocationValueChange()">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-dropdown>
				</div>

				<button class="ui fluid blue button"
					[disabled]="allLocationsDisabled"
					(click)="submit()">
					Continue
				</button>
			</div>
		</div>
	</div>
</div>