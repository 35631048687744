<div class="field">
	<label>Card Number:</label>
	<div class="fields">
		<div class="four wide field" app-field [error]="validation | path: 'amisCardNumber'">
			<input type="text" [(ngModel)]="amisCardModel.amisCardNumber">
		</div>
		<div class="field">
			<button class="ui fluid primary button" [disabled]="!isValid" (click)="onSubmit()">Search</button>
		</div>
	</div>
</div>