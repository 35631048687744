import { Any, JsonProperty } from '@nstep-common/utils';

export class PinConfirmationModel {
	pin!: string;
}

export class CardDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	cardNumber!: string;

	@JsonProperty({ type: Date })
	startDate!: Date;

	@JsonProperty({ type: Date })
	endDate!: Date;

	@JsonProperty({ type: String })
	entitlementCode!: string;

	@JsonProperty({ type: Number })
	fuelOverride!: number;
}

export class AllowedDto {
	@JsonProperty({ type: Number })
	amisCardId!: number;

	@JsonProperty({ type: String })
	amisCardNumber!: string;

	@JsonProperty({ type: Number })
	headquarterId!: number;

	@JsonProperty({ type: String })
	headquarter!: string;

	@JsonProperty({ type: Number })
	entitlementCodeId!: number;

	@JsonProperty({ type: String })
	entitlementCode!: string;

	@JsonProperty({ type: Number })
	entitlementTypeId!: number;

	@JsonProperty({ type: String })
	entitlementType!: string;

	@JsonProperty({ type: Number, nullable: true })
	measurementUnitId: number | null = null;

	@JsonProperty({ type: String })
	measurementUnit!: string;

	@JsonProperty({ type: Boolean })
	rationed!: boolean;

	@JsonProperty({ type: String })
	balance!: string;

	@JsonProperty({ type: Any, nullable: true })
	issuance!: any;

	@JsonProperty({ type: String })
	measurementUnitDescription!: string;

	@JsonProperty({ type: Number })
	minimumBuyAmount!: number;

	available!: boolean;
	parsedBalance!: number;
	transactionAmount!: number;

	constructor(data: AllowedDto | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ForbiddenDto {
	@JsonProperty({ type: String })
	entitlementType!: string;

	@JsonProperty({ type: Boolean })
	rationed!: boolean;

	available!: boolean;
}

export class PurchaseBalanceDto {
	@JsonProperty({ type: CardDto })
	card!: CardDto;

	@JsonProperty({ type: AllowedDto, array: true })
	allowed: AllowedDto[] = [];

	@JsonProperty({ type: ForbiddenDto, array: true })
	forbidden: ForbiddenDto[] = [];
}

export class OnlineTransactionActionModel {
	action!: string;
	currentTabIndex!: number;
	pin?: string | null;
}

export class EntitlementTransactionDtoModel {
	amiscardId!: number;
	entitlementCodeId!: number;
	entitlementTypeId!: number;
	measurementUnitAssociationId: number | null = null;
	posOperatorId: number | null = null;
	deviceTransactionId: string | null = null;
	statusId!: number;
	transactionTypeId!: number;
	transactionAmount!: number;
	timeStamp!: string;
	notes: string | null = null;
	aggregatedIdentifier: string | null = null;
	headquarterId!: number;
	posLocationId!: number;

	constructor(data: Partial<EntitlementTransactionDtoModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export interface AggregateTransactions {
	transactions: EntitlementTransactionDtoModel[];
}