import { JsonProperty } from "@nstep-common/utils";

export class ClientVerifyModel {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name: string = '';

	@JsonProperty({ type: String })
	loginUrl: string = '';
}

export class ClientAuthorizeModel {
	@JsonProperty({ type: String })
	redirectUrl: string = '';
}