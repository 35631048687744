import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnlineTransactionActionModel, AllowedDto } from '@nstep-public/pages';

@Component({
	selector: 'app-non-rationed-types',
	templateUrl: './non-rationed-types.component.html'
})
export class NonRationedTypesComponent {
	@Input() nonRationedItems: AllowedDto[] = [];
	@Input() hasRationedItems: boolean = false;

	@Output() action = new EventEmitter<OnlineTransactionActionModel>();

	cancel(): void {
		this.action.emit({ action: 'cancel', currentTabIndex: 1 });
	}

	next(): void {
		this.action.emit({ action: 'next', currentTabIndex: 1 });
	}
}
