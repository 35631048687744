import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { HeadquarterDto } from '@nstep-public/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HeadquarterService {

	constructor(private apiService: ApiService) {
	}

	getHeadquarters(): Observable<HeadquarterDto[]> {
		return this.apiService.get([HeadquarterDto], 'hq');
	}
}
