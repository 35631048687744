import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { BaseComponent, PageItem, PaginationModel, ColumnSortation, TableColumn, TableColumnData, TableGroup, TreeNode } from "@nstep-common/core";
import { Debounce, valueHasChanged } from "@nstep-common/utils";
import { chain, chunk, every, filter, find, flatMap, includes, indexOf, isNil, map, max, orderBy, range, reduce, some, sortBy, sumBy } from 'lodash';

@Component({
	selector: 'app-table-view',
	templateUrl: './table-view.component.html',
	styleUrls: ['./table-view.component.css']
})
export class TableViewComponent extends BaseComponent implements AfterViewInit, OnChanges {
	@Input() isBasic = false;
	@Input() isCompact = true;
	@Input() isCelled = true;
	@Input() isStriped = false;
	@Input() isCollapsing = false;
	@Input() isDefinition = false;
	@Input() noSearch = false;
	@Input() allowMultiColSort = false;
	@Input() itemTemplate: TemplateRef<any> | null = null;

	@Input() data: any[] = [];
	@Input() dataReady = true;

	@Input() columns: TableColumn[] = [];
	@Input() groups: TableGroup[] = [];

	@Output() pageChangeEvent = new EventEmitter<PaginationModel>();
	@Output() sortChangeEvent = new EventEmitter<ColumnSortation>();

	colData = new Map<TableColumn, TableColumnData>();

	filterData: any[] = [];
	sortData: any[] = [];
	pageData: any[] = [];
	treeData: any[] = [];

	pages: number[] = [];

	@Input() useServerPagination = false;

	maxPagesCount = 5;
	@Input() pageSize = 10;
	@Input() currentPage = 1;
	@Input() totalPages = 1;

	get groupedColumns(): TableColumn[] {
		const groupKeys = flatMap(this.groups, g => g.keys);

		return chain(this.columns)
			.filter(col => col.name != '')
			.filter(col => !!this.colData.get(col)?.group && !this.colData.get(col)?.group?.header)
			.orderBy(col => indexOf(groupKeys, col.key))
			.value();
	}

	get ungroupedColumns(): TableColumn[] {
		return chain(this.columns)
			.filter(col => col.name != '')
			.filter(col => !this.colData.get(col)?.group || !!this.colData.get(col)?.group?.header)
			.value();
	}

	constructor(private elementRef: ElementRef) {
		super();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			$(this.elementRef.nativeElement)
				.find('.ui.dropdown.app-filter-dropdown')
				.dropdown({
					action: 'nothing'
				});
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (valueHasChanged(changes['columns']) ||
			valueHasChanged(changes['groups'])) {
			for (const col of this.columns) {
				this.colData.set(col, new TableColumnData({
					group: find(this.groups, g => includes(g.keys, col.key)),
					sortAsc: isNil(col.sortAsc) ? null : col.sortAsc,
					filter: isNil(col.filter) ? null : col.filter
				}));
			}
		}

		if (valueHasChanged(changes['data'])) {
			this.updateData(true);
		}
	}

	filter(data: any[]): any[] {
		let filtered = filter(data, item => {
			let matched = true;

			for (const col of this.columns) {
				const selectNone = every(this.colData.get(col)?.values, v => !v.selected);
				if (selectNone) {
					continue;
				}

				let found = false;

				for (const value of this.colData.get(col)?.values || []) {
					if (!value.selected) {
						continue;
					}

					if (!col.key) {
						throw "Key not defined";
					}

					found ||= item[col.key] == value.text;
				}

				matched &&= found;
			}

			return matched;
		});

		filtered = filter(filtered, item => {
			let matched = true;

			for (const col of this.columns) {
				const searchKeyword = this.colData.get(col)?.searchKeyword?.toLowerCase()?.trim();

				if (!searchKeyword) {
					continue;
				}

				if (!col.key) {
					throw "Key not defined";
				}

				let found = false;
				const searchValue = item[col.key]?.toLowerCase()?.trim();

				switch (this.colData.get(col)?.searchOperator) {
					case 1:
					case null:
						found = new RegExp(searchKeyword, 'gi').test(searchValue);
						break;
					case 2:
						found = !(new RegExp(searchKeyword, 'gi').test(searchValue));
						break;
					case 3:
						found = new RegExp('^' + searchKeyword, 'gi').test(searchValue);
						break;
					case 4:
						found = new RegExp(searchKeyword + '$', 'gi').test(searchValue);
						break;
					case 5:
						found = searchValue == searchKeyword;
						break;
					case 6:
						found = !(searchValue == searchKeyword);
						break;
					default:
						throw 'app-table-view: invalid search operator.';
				}

				matched &&= found;
			}

			return matched;
		});

		return filtered;
	}

	sort(data: any[]): any[] {
		const sort = {};

		for (const col of this.groupedColumns) {
			if (this.colData.get(col)?.sortAsc === null) {
				continue;
			}

			if (!col.key) {
				throw "Key not defined";
			}

			(sort as any)[col.key] = this.colData.get(col)?.sortAsc ? 'asc' : 'desc';
		}

		const cols = sortBy(this.columns, c => this.colData.get(c)?.sortUpdatedAt || new Date('0001-01-01T00:00:00Z'));
		for (const col of cols) {
			if (this.colData.get(col)?.sortAsc === null) {
				continue;
			}

			if (!col.key) {
				throw "Key not defined";
			}

			(sort as any)[col.key] = this.colData.get(col)?.sortAsc ? 'asc' : 'desc';
		}

		return orderBy(data, Object.keys(sort), Object.values(sort));
	}

	paginate(data: any[], index = 0): PageItem[] {
		const groups = chain(this.groups)
			.filter(g => !!g.header)
			.value();

		const items = index < groups.length
			? chain(data)
				.groupBy(i => reduce(groups[index].keys, (prev, key) => prev + i[key], ' '))
				.map(items => {
					const groupHeader = groups[index].header;

					if (!groupHeader) {
						throw "Group header not defined";
					}

					let header = groupHeader(items);

					if (!header) {
						header = items[0];
					}

					return {
						header,
						items: this.paginate(items, index + 1)
					} as PageItem;
				})
				.value()
			: chain(data)
				.map(item => ({
					header: item,
					items: []
				}))
				.value();

		if (this.useServerPagination) {
			return items;
		}

		if (index == 0) {
			const pages = chunk(items, this.pageSize);
			this.totalPages = pages.length;

			if (!pages.length) {
				return [];
			}

			const pageNum = Math.min(this.currentPage - 1, pages.length - 1);
			const page = pages[pageNum];

			return page;
		}

		return items;
	}

	toTree(data: PageItem[], index = 0): TreeNode[] {
		const groups = chain(this.groups)
			.filter(g => !g.header)
			.value();

		if (index >= groups.length) {
			return chain(data)
				.map(i => ({
					key: null,
					collapsed: true,
					level: index,
					data: i.header,
					children: !i.items.length ? [] : this.toTree(i.items, index + 1)
				} as TreeNode))
				.value();
		}

		const treeData = chain(data)
			.groupBy(i => reduce(groups[index].keys, (prev, key) => prev + i.header[key], ' '))
			.map((items, key) => ({
				key: key,
				collapsed: false,
				level: index,
				data: null,
				children: this.toTree(items, index + 1)
			} as TreeNode))
			.value();

		return treeData;
	}

	updateCols(): void {
		const lastFilterUpdatedAt = max(map(Array.from(this.colData.values()), cd => cd.filterUpdatedAt));

		for (const col of this.columns) {
			if (!col.key) {
				continue;
			}

			const colData = this.colData.get(col);

			if (!colData) {
				throw 'Col data not defiend';
			}

			if (!colData.values.length) {
				colData.values = chain(this.data)
					.map(item => item[col.key || ''])
					.uniq()
					.sort()
					.map(val => ({
						text: val,
						selected: false,
						hiddenAt: null
					}))
					.value();
			}
			else {
				colData.selectedValues = sumBy(colData.values, c => c.selected ? 1 : 0);

				for (const value of colData.values) {
					if (some(this.filterData, item => item[col.key || ''] == value.text)) {
						value.hiddenAt = value.hiddenAt && lastFilterUpdatedAt && value.hiddenAt >= lastFilterUpdatedAt ? null : value.hiddenAt;
					}
					else {
						value.hiddenAt = colData.filterUpdatedAt == lastFilterUpdatedAt ? null : lastFilterUpdatedAt;
					}

					if (value.selected && value.hiddenAt) {
						colData.selectedValues--;
					}
				}
			}
		}
	}

	private updatePagesFromServer(): void {

		if (!this.totalPages) {
			this.pages = [1];
			return;
		}

		const allPages = range(1, this.totalPages + 1);
		const pageInterval = chunk(allPages, this.maxPagesCount);
		this.pages = pageInterval.find(p => p.includes(this.currentPage))!;
	}

	private updatePagesFromLocal(): void {
		if (!this.pages.length) {
			this.pages = range(1, Math.min(this.totalPages, this.maxPagesCount));
			return;
		}

		let min = Math.min(... this.pages);
		let max = Math.min(Math.max(... this.pages), this.totalPages);

		if (this.currentPage == min - 1) {
			min = Math.max(this.currentPage - this.maxPagesCount + 1, 1);
			max = this.currentPage;

			if (max - min < this.maxPagesCount) {
				max = Math.min(min + this.maxPagesCount - 1, this.totalPages - 1);
			}
		}

		if (this.currentPage == max + 1 || min == max) {
			min = this.currentPage;
			max = Math.min(this.currentPage + this.maxPagesCount - 1, this.totalPages);

			if (max - min < this.maxPagesCount) {
				min = Math.max(1, max - this.maxPagesCount + 1);
			}
		}

		this.pages = range(min, max + 1);
	}

	filterByColEl(el: HTMLElement, col: TableColumn) {
		$(el).dropdown('toggle');

		this.filterByCol(col);
		this.updateData();
	}

	filterByCol(col: TableColumn) {
		const colData = this.colData.get(col);

		if (!colData) {
			throw "Col data not defined";
		}

		const selectedValues = sumBy(colData.values, c => c.selected ? 1 : 0);

		colData.filterUpdatedAt = !selectedValues ? null : new Date();
	}

	resetCol(col: TableColumn, val: boolean) {
		for (const value of this.colData.get(col)?.values || []) {
			if (value.hiddenAt) {
				continue;
			}

			value.selected = val;
		}
	}

	@Debounce(1000)
	searchByCol(col: TableColumn, search: { keyword?: string, operator?: number | null }): void {
		let paramsReceived = false;
		const colData = this.colData.get(col);

		if (!colData) {
			throw "Col data not defined";
		}

		if (search.keyword !== undefined) {
			colData.searchKeyword = search.keyword;
			paramsReceived = true;
		}

		if (search.operator !== undefined) {
			colData.searchOperator = search.operator;
			paramsReceived = true;
		}

		if (!paramsReceived) {
			return;
		}

		this.updateData();
	}

	updateData(init = false) {
		this.filterData = init ? this.data : this.filter(this.data);

		this.updateCols();

		this.sortData = this.sort(this.filterData);
		this.pageData = this.paginate(this.sortData);
		this.treeData = this.toTree(this.pageData);

		if (this.useServerPagination) {
			this.updatePagesFromServer();
		} else {
			this.updatePagesFromLocal();
		}
	}

	resetAllCols() {
		this.columns.forEach((col) => {
			const colData = this.colData.get(col);

			if (!colData) {
				throw "Column data not defined";
			}

			colData.values = [];
			colData.selectedValues = 0;
			colData.sortAsc = null;
			colData.searchKeyword = null;

			this.filterByCol(col);
			this.resetCol(col, false);
		});
	}

	resetOtherCols(currentCol: TableColumn) {
		this.columns.filter(col => col.name !== currentCol.name)
			.forEach((col) => {
				const colData = this.colData.get(col);

				if (!colData) {
					throw "Column data not defined";
				}

				colData.values = [];
				colData.selectedValues = 0;
				colData.sortAsc = null;
				colData.searchKeyword = null;

				this.filterByCol(col);
				this.resetCol(col, false);
			});
	}

	sortByCol(col: TableColumn) {

		if (!this.allowMultiColSort) this.resetOtherCols(col);

		const colData = this.colData.get(col);

		if (!colData) {
			throw "Col data not defined";
		}

		colData.sortAsc = colData.sortAsc === null
			? true
			: colData.sortAsc === true
				? false
				: null;

		if (this.useServerPagination) {
			this.sortChangeEvent.emit(new ColumnSortation({ key: col.key!, sortAsc: colData.sortAsc }));
			return;
		}

		colData.sortUpdatedAt = new Date();

		this.sortData = this.sort(this.filterData);
		this.pageData = this.paginate(this.sortData);
		this.treeData = this.toTree(this.pageData);
	}

	changePage(page: number) {
		if (page < 1 || page > this.totalPages) {
			return;
		}

		this.currentPage = page;

		if (this.useServerPagination) {
			this.pageChangeEvent.emit(new PaginationModel({ pageSize: this.pageSize, currentPage: this.currentPage, totalPages: this.totalPages }));
		} else {
			this.pageData = this.paginate(this.sortData);
			this.treeData = this.toTree(this.pageData);
			this.updatePagesFromLocal();
		}
	}
}
