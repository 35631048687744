<h2 class="ui header">Entitlement Check</h2>

<div app-loading [enabled]="loading" class="ui form">
	<div class="field">
		<label>Card Number:</label>
		<div class="fields">
			<div class="four wide field" app-field [error]="validation | path: 'amisCardNumber'">
				<input type="text" [(ngModel)]="entitlementCheckModel.amisCardNumber">
			</div>
			<div class="field">
				<button class="ui fluid primary button" [disabled]="!isValid" (click)="onSubmit()">Submit</button>
			</div>
		</div>
	</div>

	<ng-container *ngIf="posLocationDropdownValues.length">
		<div class="ui divider"></div>
		<div class="eight wide field">
			<label>POS Location - HQ</label>
			<sm-dropdown class="ui selection dropdown"
				[(value)]="selectedPosLocationHqPairId"
				[options]="posLocationDropdownValues"
				(valueChange)="onPosLocationValueChange()">
				<div class="text"></div>
				<i class="dropdown icon"></i>
			</sm-dropdown>
		</div>

		<div class="field">
			<h3>POS Location <span class="ui green text">{{selectedProccessedEntitlementType.posLocationName}}</span> : Code <span class="ui purple text">{{selectedProccessedEntitlementType.fullCode}}</span> - <span class="ui teal text">{{selectedProccessedEntitlementType.hqName}}</span> HQ</h3>
		</div>

		<ng-container *ngIf="selectedProccessedEntitlementType.entitlementTypes.length">
			<div class="ui divider"></div>
			<div class="eight wide field">
				<div class="ui segments">
					<ng-container *ngFor="let type of selectedProccessedEntitlementType.entitlementTypes">
						<div class="ui red segment" *ngIf="!type.available">
							<del>
								<h3>{{type.name}}</h3>
							</del>
						</div>
						<div class="ui green segment" *ngIf="type.available">
							<h3>{{type.name}}</h3>
						</div>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</ng-container>
</div>