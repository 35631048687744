import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '@nstep-common/semantic-ui';

@Component({
	selector: 'app-cancel-transaction',
	templateUrl: './cancel-transaction.component.html',
})
export class CancelTransactionComponent {
	@ViewChild('cancelTransactionConfimationModal') cancelTransactionConfimationModal!: ModalComponent;

	@Output() cancelTransactionEvent = new EventEmitter();

	cancelTransaction(): void {
		this.cancelTransactionEvent.emit();
	}

	toggle(): void {
		this.cancelTransactionConfimationModal.toggle();
	}
}
