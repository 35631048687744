import { Component, OnInit } from '@angular/core';
import { BaseComponent, TableColumn } from '@nstep-common/core';
import { UserCardBalanceModel } from 'projects/common/core/models/balance.models';
import { chain } from 'lodash';
import { DropdownOption } from '@nstep-common/semantic-ui';
import { BalanceService } from '@nstep-public/shared';

@Component({
	selector: 'app-balance',
	templateUrl: './balance.component.html'
})
export class BalanceComponent extends BaseComponent implements OnInit {
	tableData: any[] = [];
	tableDataReady = false;
	
	cardsDropdownValues: DropdownOption[] = [];
	selectedCardNumber: string = '';
	cardsDropdownDataReady = false;

	rationsDropdownValues: DropdownOption[] = [
		new DropdownOption({ name: 'Rationed Items', value: 1 }),
		new DropdownOption({ name: 'Non Rationed Items', value: 2 })
	];
	selectedRationedTypeId: number = 1;

	userCardBalanceData = new UserCardBalanceModel();

	displayTableColumns: TableColumn[] = [];

	rationedTableColumns: TableColumn[] = [
		{ name: 'Entitlement Type', key: 'entitlementType', sortAsc: true },
		{ name: 'Balance', key: 'balance' },
		{ name: 'Measurement Unit', key: 'measurementUnit' },
		{ name: 'Headquarter', key: 'headquarter' },
		{ name: 'Entitlement Code', key: 'entitlementCode' }
	];

	nonRationedTableColumns: TableColumn[] = [
		{ name: 'Entitlement Type', key: 'entitlementType', sortAsc: true },
		{ name: 'Headquarter', key: 'headquarter' },
		{ name: 'Entitlement Code', key: 'entitlementCode' }
	];

	constructor(private balanceService: BalanceService) {
		super();
	}

	ngOnInit(): void {
		this.subscriptions = [
			this.balanceService.getGroupedBalance()
				.subscribe({
					next: response => {
						this.cardsDropdownValues = [];
						this.userCardBalanceData = response;

						if (this.userCardBalanceData.items.length) {
							this.selectedCardNumber = response.items[0].cardNumber;

							this.cardsDropdownValues = chain(this.userCardBalanceData.items)
								.map(e => new DropdownOption({
									value: e.cardNumber,
									name: e.cardNumber
								}))
								.value();
						}

						this.cardsDropdownDataReady = true;
						this.initializeTable(this.cardsDropdownValues.length ? this.cardsDropdownValues[0].value : null, this.rationsDropdownValues[0].value === 1);
					},
					error: () => {
						this.tableDataReady = true;
						this.cardsDropdownDataReady = true;
					}
				})
		];
	}

	initializeTable(cardNumber: string | null = null, rationed: boolean | null = null): void {
		this.displayTableColumns = rationed ? this.rationedTableColumns : this.nonRationedTableColumns;

		this.tableData = chain(this.userCardBalanceData.items)
			.filter(c => !cardNumber || c.cardNumber === cardNumber)
			.flatMap(c => c.entitlements)
			.filter(e => e.rationed === rationed)
			.map(e => ({
				entitlementType: e.entitlementType,
				balance: e.balance,
				measurementUnit: e.measurementUnit,
				headquarter: e.headquarter,
				entitlementCode: e.entitlementCode
			}))
			.value();

		this.tableDataReady = true;
	}

	onTypeValueChange(): void {
		this.initializeTable(this.selectedCardNumber, this.selectedRationedTypeId === 1);
	}

	onCardValueChange(): void {
		this.initializeTable(this.selectedCardNumber, this.selectedRationedTypeId === 1);
	}
}
