import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseComponent, TableColumn } from '@nstep-common/core';
import { DropdownOption } from '@nstep-common/semantic-ui';
import { BulkTransactionNotes, CardTransactionOnline, MeasurementUnit, TransactionState, TransactionType } from '@nstep-public/pages';
import { TransactionHistoryService } from '@nstep-public/shared';
import { chain } from 'lodash';

@Component({
	selector: 'app-transaction-history',
	templateUrl: './transaction-history.component.html',
	styleUrl: './transaction-history.component.less'
})
export class TransactionHistoryComponent extends BaseComponent implements OnInit {

	TransactionType = TransactionType;
	TransactionState = TransactionState;
	MeasurementUnit = MeasurementUnit;

	tableData: any[] = [];
	tableDataReady = true;

	tableColumns: TableColumn[] = [
		{ name: 'Card Number', key: 'cardNumber', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Headquarter', key: 'headquarter', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Transaction Amount', key: 'transactionAmount', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Measurement Unit', key: 'measurementUnit', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Time Stamp', key: 'timeStamp', sortAsc: false, isCellCentered: true, isHeaderCentered: true },
		{ name: 'POS Location', key: 'posLocation', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Transaction Status', key: 'transactionStatus', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Transaction Type', key: 'transactionType', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Start Date', key: 'startDate', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Expiration Date', key: 'expirationDate', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Notes', isHeaderCentered: true }
	];

	cardsDropdownValues: DropdownOption[] = [];
	selectedCardNumber: string | null = null;

	cardEntitlementTypesDropdownValues: DropdownOption[] = [];
	selectedEntitlementType: string | null = null;

	cardEntitlementTransactionsDictionary: { [key: string]: { [key: string]: CardTransactionOnline[] } } = {};

	bulkTransactionStatuses: string[] = [TransactionState.Closed, TransactionState.Active];
	notesMaximumDisplayLength = 25;

	constructor(private transactionHistoryService: TransactionHistoryService) {
		super();
	}

	ngOnInit(): void {
		this.initializeTable();
	}

	initializeTable(): void {
		this.tableData = [];
		this.tableDataReady = false;

		this.subscriptions.push(
			this.transactionHistoryService.getUserTransactions().subscribe({
				next: response => {

					response.forEach(group => {
						this.cardEntitlementTransactionsDictionary[group.cardNumber] = {};

						group.types.forEach(type => {
							this.cardEntitlementTransactionsDictionary[group.cardNumber][type.entitlementType] = type.transactions;
						});
					});

					this.cardsDropdownValues = chain(response)
						.map(cardNumberTypes => new DropdownOption({
							name: cardNumberTypes.cardNumber,
							value: cardNumberTypes.cardNumber,
						}))
						.value();

					this.selectedCardNumber = this.cardsDropdownValues[0].name;

					this.populateEntitlementTypeDropdown();
					this.populateTable();
				},
				error: () => {
					this.tableDataReady = true;
				}
			})
		);
	}

	onCardValueChange(): void {
		this.populateEntitlementTypeDropdown();

		this.tableData = [];
		this.tableDataReady = false;

		this.populateTable();
	}

	onEntitlementTypeValueChange(): void {
		this.tableData = [];
		this.tableDataReady = false;

		this.populateTable();
	}

	toggleNotes(item: any) {
		item.displayNotes = item.notesFullyDisplayed ? item.notes.substring(0, this.notesMaximumDisplayLength) : item.notes;
		item.notesFullyDisplayed = !item.notesFullyDisplayed;
	}

	private populateTable(): void {

		const currentCardTransactions = this.cardEntitlementTransactionsDictionary[this.selectedCardNumber!][this.selectedEntitlementType!];

		this.tableData = chain(currentCardTransactions)
			.filter(e => e.transactionStatus !== TransactionState.Expired)
			.map(e => {

				let newNotes = '';

				switch (e.transactionStatus) {
					case TransactionState.Closed:
						const expiredRecords = currentCardTransactions.find(t => t.bulkId === e.id && t.transactionStatus == TransactionState.Expired);
						if (expiredRecords)
							newNotes = expiredRecords.transactionAmount + e.transactionAmount === 0 ? BulkTransactionNotes.FullyExpired : BulkTransactionNotes.PartiallyExpired;
						break;
					case TransactionState.Active:
						const transactedRecords = currentCardTransactions.filter(t => t.bulkId === e.id && t.transactionStatus == TransactionState.Transacted);
						if (transactedRecords.length)
							newNotes = chain(transactedRecords).sumBy(t => t.transactionAmount).value() === 0 ? BulkTransactionNotes.FullyConsumed : BulkTransactionNotes.PartiallyConsumed;
						break;
				}

				const currentNotes = this.bulkTransactionStatuses.includes(e.transactionStatus) ? newNotes : e.notes;
				const largerThan25Characters = currentNotes != null && currentNotes.length > this.notesMaximumDisplayLength;

				return {
					cardNumber: e.cardNumber,
					headquarter: e.headquarter,
					entitlementType: e.entitlementType,
					transactionAmount: e.transactionAmount,
					measurementUnit: e.measurementUnit,
					timeStamp: formatDate(new Date(e.timeStamp), 'dd/MM/yyyy - h:mm a', 'en'),
					posLocation: e.posLocation,
					transactionStatus: e.transactionStatus,
					transactionType: e.transactionType,
					startDate: e.startDate ? formatDate(new Date(e.startDate), 'dd/MM/yyyy - h:mm a', 'en') : '-',
					expirationDate: e.expirationDate ? formatDate(new Date(e.expirationDate), 'dd/MM/yyyy - h:mm a', 'en') : '-',
					notes: currentNotes,
					displayNotes: largerThan25Characters ? currentNotes.substring(0, this.notesMaximumDisplayLength) : currentNotes,
					notesFullyDisplayed: !largerThan25Characters
				};
			})
			.value();

		this.tableDataReady = true;
	}

	private populateEntitlementTypeDropdown(): void {
		this.cardEntitlementTypesDropdownValues = chain(Object.keys(this.cardEntitlementTransactionsDictionary[this.selectedCardNumber!]))
			.map(type => new DropdownOption({
				name: type,
				value: type
			}))
			.orderBy(o => o.name)
			.value();

		this.selectedEntitlementType = this.cardEntitlementTypesDropdownValues[0].name;
	}
}
