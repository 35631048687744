<sm-modal #cancelTransactionConfimationModal [isTiny]="true">
	<ng-container header>Cancel Transaction</ng-container>

	<ng-container content>
		<p>Are you sure you want to cancel this transaction?</p>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="cancelTransactionConfimationModal.toggle();">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			(click)="cancelTransaction(); cancelTransactionConfimationModal.toggle();">
			Confirm
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>