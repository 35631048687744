import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AmisCardService {

	private amisCardNumber = new BehaviorSubject<string>('');
	currentAmisCardNumberValue = this.amisCardNumber.asObservable();

	constructor() { }

	changeAmisCardNumber(amisCardNumber: string): void {
		this.amisCardNumber.next(amisCardNumber);
	}
}
