<div class="ui stackable grid">
	<ng-container *ngIf="currentPosLocationInfo">
		<div class="row">
			<div class="ten wide column">
				<h4>POS Location: <span class="ui green text">{{currentPosLocationInfo.location.name}}</span> &nbsp;&nbsp;AMIS Card: <span class="ui purple text">{{currentPosLocationInfo.card.cardNumber}}</span> </h4>
			</div>
			<div class="right aligned six wide column">
				<p>{{currentDate | date : "dd/MM/yyyy - h:mm:ss"}}</p>
			</div>
		</div>

		<div class="row" *ngIf="currentPosLocationInfo.card.expirationDays <= 60">
			<div class="column">
				<h4 class="ui header red">
					Your AMIS card or posting will expire in {{currentPosLocationInfo.card.expirationDays}} days on {{currentPosLocationInfo.card.expirationDate}} - please contact your Registration Office.
				</h4>
			</div>
		</div>

		<div class="row" *ngIf="currentPosLocationInfo.operator.expirationDays <= 60">
			<div class="column">
				<h4 class="ui header red">
					Your NSTEP Operator Account will expire in {{currentPosLocationInfo.operator.expirationDays}} days on {{currentPosLocationInfo.operator.expirationDate}} - please contact your HRDS Regional Administrator or HRDS Help Desk.
				</h4>
			</div>
		</div>
		<div class="ui divider" style="margin-top: 0; margin-bottom: 0;"></div>
	</ng-container>

	<div class="row">
		<div class="ten wide column">
			<h2 class="ui header">Online Transactions</h2>
		</div>
		<div class="right aligned six wide column" *ngIf="currentTab!=tabs[0] && currentTab!=tabs[4]">
			<button class="ui right floated negative basic button" (click)="cancelTransactionModal.toggle();">Cancel Transaction</button>
		</div>
	</div>
</div>

<div class="ui form" app-loading [enabled]="loading">

	<app-amis-card-search *ngIf="currentTab === tabs[0]" (submitEvent)="search($event);"></app-amis-card-search>

	<ng-container *ngIf="currentTab !== tabs[0] && purchaseBalanceData">
		<div class="inline field" style="margin: 0;">
			<label>Patron AMIS Card Number:</label><span>{{purchaseBalanceData.card.cardNumber}}</span>
		</div>
		<div class="inline field" style="margin: 0;">
			<label>Card Expiration Date:</label><span>{{purchaseBalanceData.card.endDate | date : 'dd/MM/yyyy'}}</span>
		</div>
		<div class="inline field" style="margin: 0;">
			<label>Entitlement Code:</label><span>{{purchaseBalanceData.card.entitlementCode}}</span>
		</div>
		<div class="inline field" style="margin: 0;">
			<label>Fuel Override:</label><span>{{purchaseBalanceData.card.fuelOverride}}</span>
		</div>
	</ng-container>


	<container-element [ngSwitch]="currentTab">
		<app-non-rationed-types *ngSwitchCase="tabs[1]" [nonRationedItems]="nonRationedItems" [hasRationedItems]="rationedItems.length > 0" (action)="onAction($event);"></app-non-rationed-types>
		<app-rationed-types *ngSwitchCase="tabs[2]" [currentTabIndex]="2" [(rationedItems)]="rationedItems" (action)="onAction($event);"></app-rationed-types>
		<app-rationed-types *ngSwitchCase="tabs[3]" [currentTabIndex]="3" [rationedItems]="rationedItems" (action)="onAction($event);"></app-rationed-types>
		<app-rationed-types *ngSwitchCase="tabs[4]" [currentTabIndex]="4" [rationedItems]="rationedItems" [attempts]="attempts" (action)="onAction($event);"></app-rationed-types>
	</container-element>
</div>

<app-cancel-transaction (cancelTransactionEvent)="cancelTransaction()"></app-cancel-transaction>

<sm-modal *ngIf="currentPosLocationInfo" #accessDeniedModal [isTiny]="true" [noClose]="true">
	<ng-container header>Access Denied</ng-container>

	<ng-container content>
		<div class="ui grid">
			<div class="two wide column">
				<i class="huge red exclamation triangle icon"></i>
			</div>
			<div class="fourteen wide column">
				<p>
					<strong>Access to {{currentPosLocationInfo.location.name}} has been <span class="ui inverted red text">denied</span></strong> and your session has been terminated. <br />OSC-0000019-00
					Please login on another available POS Location.
				</p>
			</div>
		</div>
	</ng-container>

	<ng-container actions>
		<button class="ui primary right labeled icon button"
			(click)="logout()">
			Ok
			<i class="check icon"></i>
		</button>
	</ng-container>
</sm-modal>