import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { ClientAuthorizeModel, ClientVerifyModel } from '@nstep-public/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ClientsService {

	constructor(private apiService: ApiService) { }

	verifyClient(clientId: string): Observable<ClientVerifyModel> {
		return this.apiService.get(ClientVerifyModel, `clients/verify/${clientId}`);
	}

	authorizeClient(id: number): Observable<ClientAuthorizeModel> {
		return this.apiService.post(ClientAuthorizeModel, `clients/authorize/${id}`);
	}

	invalidateCache() {
		this.apiService.invalidateCache('clients');
	}
}
