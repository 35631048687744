<ng-container *ngIf="nonRationedItems.length">
	<div class="ui divider"></div>
	<div class="field">
		<div class="ui segments">
			<ng-container *ngFor="let type of nonRationedItems">
				<div class="ui red segment" *ngIf="!type.available">
					<del>
						<h3>{{type.entitlementType}}</h3>
					</del>
				</div>
				<div class="ui green segment" *ngIf="type.available">
					<h3>{{type.entitlementType}}</h3>
				</div>
			</ng-container>
		</div>
	</div>
</ng-container>

<div class="fields">
	<div class="field">
		<button class="ui negative basic button" (click)="cancel()">Return</button>
	</div>
	<div class="field" *ngIf="hasRationedItems">
		<button class="ui positive basic button" (click)="next()">Next</button>
	</div>
</div>