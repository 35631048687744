<app-base-layout>
	<ng-container title>
		<div class="ui green label">Public</div>
	</ng-container>

	<ng-container menu>
		<br />
		<div id="side-menu"
			class="ui vertical fluid large text menu">
			<a class="blue item"
				[routerLink]="['balance']"
				[routerLinkActive]="['active']"
				[appAccessHider]="[permissions.balance]">
				<i class="balance scale left icon"></i>&nbsp;&nbsp;Balance
			</a>

			<a class="blue item"
				[routerLink]="['transaction-history']"
				[routerLinkActive]="['active']"
				[appAccessHider]="[permissions.transactionHistory]">
				<i class="exchange alternate icon"></i>&nbsp;&nbsp;Transaction History
			</a>

			<a class="item"
				[routerLink]="['entitlement-check']"
				[routerLinkActive]="['active']"
				[appAccessHider]="[permissions.entitlementCheck]">
				<i class="cubes icon"></i>&nbsp;&nbsp;Entitlement Check
			</a>

			<a class="item"
				[routerLink]="['online-transactions']"
				[routerLinkActive]="['active']"
				[appAccessHider]="[permissions.onlineTransactions]">
				<i class="exchange alternate icon"></i>&nbsp;&nbsp;Online Transactions
			</a>

			<a class="item"
				[routerLink]="['add-balance']"
				[routerLinkActive]="['active']"
				[appAccessHider]="[permissions.addBalance]">
				<i class="balance scale left icon"></i>&nbsp;&nbsp;Add Balance
			</a>

			<a class="item"
				[routerLink]="['select-location']"
				[routerLinkActive]="['active']"
				[appAccessHider]="[permissions.changeLocation]">
				<i class="map marker alternate icon"></i>&nbsp;&nbsp;Change Location
			</a>

			<a class="item"
				[routerLink]="['reports']"
				[routerLinkActive]="['active']"
				[appAccessHider]="[permissions.reports]">
				<i class="chart pie icon"></i>&nbsp;&nbsp;Reports
			</a>

			<a class="item"
				href="https://s.imageplus.digital/wae1uu/login"
				[appAccessHider]="[permissions.reports]"
				target="_blank" >
				<i class="gas pump icon"></i>&nbsp;&nbsp;Total Register
			</a>

			<div class="item"
				style="margin-top: 25px;">
				<div class="header">
					DTA Community Connect
				</div>
				<div class="menu">
					<a class="blue item"
						[attr.href]="serverSettings?.DTA?.SetupUrl"
						target="_blank">
						<i class="toolbox icon" style="float: none"></i>&nbsp;&nbsp;Setup
					</a>
					<a class="blue item"
						[attr.href]="serverSettings?.DTA?.MainPageUrl"
						target="_blank">
						<i class="home icon" style="float: none"></i>&nbsp;&nbsp;Main Page
					</a>
					<a class="blue item"
						[attr.href]="'mailto:' + serverSettings?.DTA?.QuestionsSendTo + '?subject=' + serverSettings?.DTA?.QuestionsSubject"
						target="_blank">
						<i class="mail icon" style="float: none"></i>&nbsp;&nbsp;Questions, <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Comments, <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reset Password
					</a>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-container content>
		<router-outlet></router-outlet>
	</ng-container>
</app-base-layout>