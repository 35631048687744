import { AppPermissions } from '@nstep-public/shared';

export const routePermissions: { [route: string]: string[] } = {
	'balance': [AppPermissions.balance],
	'transaction-history': [AppPermissions.transactionHistory],
	'entitlement-check': [AppPermissions.entitlementCheck],
	'online-transactions': [AppPermissions.onlineTransactions],
	'add-balance': [AppPermissions.addBalance],
	'select-location': [AppPermissions.changeLocation],
	'reports': [AppPermissions.reports]
};
