<h3 class="ui header red" *ngIf="userCardBalanceData.expirationDays <= 60">
	Your AMIS card or posting will expire in {{userCardBalanceData.expirationDays}} days on {{userCardBalanceData.expirationDate}} - please contact your Registration Office.
</h3>

<h2 class="ui header">Balance</h2>

<div class="ui form grid">
	<div class="three column row">
		<div class="column">
			<div class="field">
				<label>Card Number:</label>
				<sm-dropdown class="ui fluid selection dropdown label"
					[class.loading]="!cardsDropdownDataReady"
					[(value)]="selectedCardNumber"
					[options]="cardsDropdownValues"
					(valueChange)="onCardValueChange()">
					<div class="text"></div>
					<i class="dropdown icon"></i>
				</sm-dropdown>
			</div>
		</div>
		<div class="column">
			<div class="field">
				<label>Rationed Type:</label>
				<sm-dropdown class="ui fluid selection dropdown label"
					[(value)]="selectedRationedTypeId"
					[options]="rationsDropdownValues"
					(valueChange)="onTypeValueChange()">
					<div class="text"></div>
					<i class="dropdown icon"></i>
				</sm-dropdown>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="column">
			<app-table-view
				[data]="tableData"
				[noSearch]="true"
				[dataReady]="tableDataReady"
				[columns]="displayTableColumns">
			</app-table-view>
		</div>
	</div>
</div>