import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, UserCardBalanceModel } from '@nstep-common/core';

@Injectable({
	providedIn: 'root'
})
export class BalanceService {
	constructor(private apiService: ApiService) {
	}

	getGroupedBalance(): Observable<UserCardBalanceModel> {
		return this.apiService.get(UserCardBalanceModel, 'balance/grouped');
	}
}
