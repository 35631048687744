import { Component } from '@angular/core';
import { BaseComponent } from '@nstep-common/core';
import { DropdownOption } from '@nstep-common/semantic-ui';
import { createProxy, toast } from '@nstep-common/utils';
import {
	EntitleShortDto,
	EntitlementCheckModel,
	EntitlementCheckModelValidator,
	EntitlementTypeSegment,
	ProccessedEntitlementTypes,
	UnrationedBalanceModel
} from '@nstep-public/pages';
import { HeadquarterService, PosOperatorService } from '@nstep-public/shared';
import { ValidationErrors } from 'fluentvalidation-ts';
import { flatten } from 'lodash';
import { finalize, forkJoin, Observable, tap } from 'rxjs';

@Component({
	selector: 'app-entitlement-check',
	templateUrl: './entitlement-check.component.html'
})
export class EntitlementCheckComponent extends BaseComponent {

	loading = false;

	headquartersDictionary: { [key: number]: string } = {};
	entitlementsDictionary: { [key: number]: string } = {};
	cardEntitlementsDictionary: { [key: number]: EntitleShortDto } = {};

	responseData: UnrationedBalanceModel = {} as UnrationedBalanceModel;

	posLocationDropdownValues: DropdownOption[] = [];
	selectedPosLocationHqPairId: string | null = null;

	proccessedEntitlementTypes: ProccessedEntitlementTypes[] = [];
	selectedProccessedEntitlementType = new ProccessedEntitlementTypes();

	isValid = false;

	entitlementCheckModel: EntitlementCheckModel = createProxy(new EntitlementCheckModel(), {
		set: () => this.validate(this.entitlementCheckModel)
	});

	validation: ValidationErrors<EntitlementCheckModel> = {};

	validate(value: EntitlementCheckModel): void {
		const validator = new EntitlementCheckModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	constructor(private posOperatorService: PosOperatorService,
		private headquarterService: HeadquarterService) {
		super();
	}

	onSubmit(): void {
		this.loading = true;
		this.posLocationDropdownValues = [];

		this.subscriptions.push(
			this.posOperatorService.getAmisCardEntitlements(this.entitlementCheckModel.amisCardNumber).subscribe({
				next: response => {

					if (response.cardEntitlements.length === 0 || response.posLocation.length === 0) {
						this.loading = false;
						toast('Warning', 'There are no available Non Rationed Items for the selected AMIS Card Number', 'orange');
						return;
					}

					this.responseData = response;

					this.responseData.cardEntitlements.forEach(entitlement => {
						if (entitlement.headquarterId) {
							this.cardEntitlementsDictionary[entitlement.headquarterId] = entitlement;
						}
					});

					const forkJoinSubscription$ = forkJoin([this.getHeadquarters(), this.getTypes()])
						.pipe(finalize(() => {
							this.loading = false;
						}))
						.subscribe();

					this.subscriptions.push(forkJoinSubscription$);
				},
				error: (response: { [key: string]: string[] }) => {
					this.loading = false;

					const errors = flatten(Object.values(response));
					errors.forEach(error => {
						toast('Error', error, 'red');
					});
				}
			})
		);
	}

	private getHeadquarters(): Observable<any> {
		const getHeadquarters$ = this.headquarterService.getHeadquarters()
			.pipe(tap(response => {
				response.forEach(hq => {
					this.headquartersDictionary[hq.id] = hq.name;
				});
			}));

		return getHeadquarters$;
	}

	private getTypes(): Observable<any> {
		const neededEntitlementTypeIds = this.responseData.posLocation.flatMap(pl => pl.hQs.flatMap(hq => hq.types));

		const getTypes$ = this.posOperatorService.getTypes(neededEntitlementTypeIds).
			pipe(tap(response => {
				response.forEach(type => {
					this.entitlementsDictionary[type.id] = type.name;
				});

				this.initializeData();

				this.selectedPosLocationHqPairId = this.posLocationDropdownValues[0].value;
				this.selectedProccessedEntitlementType = this.proccessedEntitlementTypes[0];
			}));

		return getTypes$;
	}

	initializeData(): void {
		this.responseData.posLocation.forEach(pl => {
			pl.hQs.forEach(hq => {

				const code = this.cardEntitlementsDictionary[hq.id].code;

				const proccessedEntitlementType = new ProccessedEntitlementTypes({
					fullCode: `${code.majorCode}.${code.minorCode}`,
					posLocationName: pl.name,
					posLocationId: pl.id,
					hqName: hq.name,
					hqId: hq.id,
				});

				const posLocationHqPairId = `${pl.id}-${hq.id}`;

				const dropdownElement = new DropdownOption({
					name: `${pl.name} (${hq.name})`,
					value: posLocationHqPairId
				});

				this.posLocationDropdownValues.push(dropdownElement);

				hq.types.forEach(id => {
					const displayType = new EntitlementTypeSegment({
						id: id,
						available: this.cardEntitlementsDictionary[hq.id].entitlements.some(e => e === id),
						name: this.entitlementsDictionary[id]
					});

					proccessedEntitlementType.entitlementTypes.push(displayType);
				});

				this.proccessedEntitlementTypes.push(proccessedEntitlementType);
			});

		});
	}

	onPosLocationValueChange(): void {
		const dropdownValueIds = this.selectedPosLocationHqPairId!.split('-').map(Number);
		const posLocationId = dropdownValueIds[0];
		const hqId = dropdownValueIds[1];

		const entitlementType = this.proccessedEntitlementTypes.find(item => item.posLocationId === posLocationId && item.hqId === hqId);
		this.selectedProccessedEntitlementType = entitlementType ? entitlementType : new ProccessedEntitlementTypes();
	}
}
