export class AppPermissions {
	static balance = '1BD7A3D1';
	static transactionHistory = '21AC2749';
	static entitlementCheck = 'F7EDFE26';
	static onlineTransactions = '731A7D18';

	static addBalance = '596C88CB';
	static changeLocation = 'AA10F6ED';

	static reports = 'B7A57568';
}