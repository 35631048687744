import { JsonProperty } from '@nstep-common/utils';

export class CardTransactionOnline {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number, nullable: true })
	bulkId!: number | null;

	@JsonProperty({ type: String })
	cardNumber!: string;

	@JsonProperty({ type: String })
	headquarter!: string;

	@JsonProperty({ type: String })
	entitlementType!: string;

	@JsonProperty({ type: String })
	measurementUnit!: string;

	@JsonProperty({ type: Number })
	transactionAmount!: number;

	@JsonProperty({ type: Date })
	timeStamp!: Date;

	@JsonProperty({ type: String, nullable: true })
	posLocation!: string | null;

	@JsonProperty({ type: String })
	transactionStatus!: string;

	@JsonProperty({ type: String })
	transactionType!: string;

	@JsonProperty({ type: Date, nullable: true })
	startDate!: Date | null;

	@JsonProperty({ type: Date, nullable: true })
	expirationDate!: string | null;

	@JsonProperty({ type: String, nullable: true })
	notes!: string | null;
}

export class GroupedTransactionsByType {
	@JsonProperty({ type: String })
	entitlementType!: string;

	@JsonProperty({ type: CardTransactionOnline, array: true })
	transactions: CardTransactionOnline[] = [];
}

export class GroupedTransactionsOnline {
	@JsonProperty({ type: String })
	cardNumber!: string;

	@JsonProperty({ type: Boolean })
	isActiveCard!: boolean;

	@JsonProperty({ type: GroupedTransactionsByType, array: true })
	types: GroupedTransactionsByType[] = [];
}

export class CardNumberEntitlementTypes {
	cardNumber!: string;
	entitlementTypes!: string[];
}

export enum TransactionType {
	InitialRations = 'Initial Rations',
	WeeklyService = 'Weekly Service',
	POSDevice = 'POS Device',
	AdminAdjustment = 'Admin Adjustment',
	PaperCard = 'Paper Card',
	WebOperator = 'Web Operator',
	WebOperatorBulk = 'Web Operator Bulk',
	TotalApplication = 'TotalApplication'
}

export enum TransactionState {
	Active = 'Active',
	Transacted = 'Transacted',
	Expired = 'Expired',
	Closed = 'Closed',
	PaperCard = 'Paper'
}

export enum MeasurementUnit {
	Liters = 'liter(s)',
	Units = 'unit(s)',
	Bottles = 'bottle(s)',
	Cans = 'can(s)',
	Grams = 'gram(s)',
	Euros = 'euro(s)',
	Injections = 'injection(s)'
}

export enum BulkTransactionNotes {
	FullyExpired = 'Fully expired',
	PartiallyExpired = 'Partially expired',
	FullyConsumed = 'Fully consumed',
	PartiallyConsumed = 'Partially consumed'
}


