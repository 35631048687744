import { ExtraValidator } from '@nstep-common/utils';
import { AddBalanceConfirmationModel, DateSelectionInputType, EntitlementPickModel } from '@nstep-public/pages';

export class EntitlementPickModelValidator extends ExtraValidator<EntitlementPickModel> {
	constructor() {
		super();

		this.ruleFor('unitCount')
			.notNull().withMessage('A valid input is required with the length between 1-4 digits!')
			.inclusiveBetween(1, 9999).withMessage('A valid input is required with the length between 1-4 digits!');
	}
}

export class AddBalanceConfirmationModelValidator extends ExtraValidator<AddBalanceConfirmationModel> {
	constructor() {
		super();

		this.ruleFor('expirationPeriodId')
			.must(expirationPeriodId => !!expirationPeriodId)
			.withMessage('Expiration period is required')
			.when(model => model.dateSelectionType === DateSelectionInputType.Dropdown);

		this.ruleFor('reasonId')
			.must(reasonId => !!reasonId)
			.withMessage('Reason is required');

		this.ruleFor('expirationDate')
			.must(expirationDate => !!expirationDate)
			.withMessage('Expiration date is required')
			.when(model => model.dateSelectionType === DateSelectionInputType.Calendar);
	}
}

