import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent, TableColumn } from '@nstep-common/core';
import { createProxy, toast } from '@nstep-common/utils';
import { OnlineTransactionActionModel, AllowedDto, PinConfirmationModel, PinConfirmationModelValidator } from '@nstep-public/pages';
import { OnlineTransactionService } from '@nstep-public/shared';
import { ValidationErrors } from 'fluentvalidation-ts';
import { chain } from 'lodash';

@Component({
	selector: 'app-rationed-types',
	templateUrl: './rationed-types.component.html',
})
export class RationedTypesComponent extends BaseComponent implements OnInit {

	@Input() currentTabIndex: number = 2;
	@Input() rationedItems: AllowedDto[] = [];
	@Input() attempts?: number;

	@Output() rationedItemsChange = new EventEmitter<AllowedDto[]>();
	@Output() action = new EventEmitter<OnlineTransactionActionModel>();

	tableData: any[] = [];
	tableDataReady = true;

	tableColumns: TableColumn[] = [
		{ name: 'Entitlement Type', key: 'entitlementType', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Purchased Amount', key: 'transactionAmount', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Maximum Value', key: 'parsedBalance', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Unit', key: 'measurementUnit', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Unit Description', key: 'measurementUnitDescription', isCellCentered: true, isHeaderCentered: true }
	];

	isValid = false;

	pinConfirmationModel: PinConfirmationModel = createProxy(new PinConfirmationModel(), {
		set: () => this.validate(this.pinConfirmationModel)
	});

	validation: ValidationErrors<PinConfirmationModel> = {};

	validate(value: PinConfirmationModel): void {
		const validator = new PinConfirmationModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	constructor(private onlineTransactionService: OnlineTransactionService) {
		super();
	}

	ngOnInit(): void {
		this.subscriptions.push(
			this.onlineTransactionService.currentPinValue.subscribe(
				(value: string) => this.pinConfirmationModel.pin = value
			)
		);

		this.tableData = chain(this.rationedItems)
			.map(e => new AllowedDto({
				entitlementType: e.entitlementType,
				transactionAmount: e.transactionAmount,
				parsedBalance: e.parsedBalance,
				measurementUnit: e.measurementUnit,
				measurementUnitDescription: e.measurementUnitDescription,
				minimumBuyAmount: e.minimumBuyAmount,
				amisCardId: e.amisCardId,
				amisCardNumber: e.amisCardNumber,
				available: e.available,
				balance: e.balance,
				entitlementCode: e.entitlementCode,
				entitlementCodeId: e.entitlementCodeId,
				entitlementTypeId: e.entitlementTypeId,
				headquarter: e.headquarter,
				headquarterId: e.headquarterId,
				issuance: e.issuance,
				measurementUnitId: e.measurementUnitId,
				rationed: e.rationed
			}))
			.value();
	}

	setPurchaseAmount(item: AllowedDto): void {
		if (item.transactionAmount && item.transactionAmount > 0) {
			//if number bigger than available amount
			if (item.transactionAmount > item.parsedBalance) {
				item.transactionAmount = item.parsedBalance;
				return;
			}
			//if number is not an exact division of the minimum buy amount
			if (item.transactionAmount % item.minimumBuyAmount !== 0) {
				if (item.transactionAmount % item.minimumBuyAmount < item.minimumBuyAmount / 2) {
					item.transactionAmount = item.transactionAmount - item.transactionAmount % item.minimumBuyAmount;
				}
				else {
					item.transactionAmount = (item.transactionAmount + item.minimumBuyAmount) - item.transactionAmount % item.minimumBuyAmount;
				}
			}
		}
		else {
			item.transactionAmount = 0;
		}

		this.rationedItemsChange.emit(this.tableData);
	}

	cancel(): void {
		this.action.emit({ action: 'cancel', currentTabIndex: this.currentTabIndex });
	}

	next(): void {
		const hasTransactions = this.tableData.some(e => e.transactionAmount > 0);

		if (hasTransactions) {
			this.action.emit({ action: 'next', currentTabIndex: this.currentTabIndex, pin: this.currentTabIndex === 4 ? this.pinConfirmationModel.pin : null });
		} else {
			toast('', 'You should make at least one transaction to go to the next step!', 'orange');
		}
	}
}
