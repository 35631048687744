import { JsonProperty } from '@nstep-common/utils';

export class AddBalanceReasonDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;
}

export class OperatorBulkTransactionModel {
	amisCardId!: number;
	headquarterId!: number;
	entitlementCodeId!: number;
	locationId!: number;

	entitlementTypeId: number | null = null;
	transactionAmount: number | null = null;
	measurementUnitId: number | null = null;
	notes: string | null = null;
	endDate: string | null = null;

	constructor(data: Partial<OperatorBulkTransactionModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class BulkTransactionTypeHistoryItemModel {
	@JsonProperty({ type: String })
	date!: string;

	@JsonProperty({ type: Number })
	transactionAmount!: number;

	@JsonProperty({ type: String })
	operator!: string;

	@JsonProperty({ type: String })
	posLocation!: string;

	constructor(data: BulkTransactionTypeHistoryItemModel | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class BulkTransactionTypeHistoryModel {
	@JsonProperty({ type: String })
	balance!: string;

	@JsonProperty({ type: BulkTransactionTypeHistoryItemModel, array: true })
	historyItems!: BulkTransactionTypeHistoryItemModel[];
}

export class EntitlementPickModel {
	unitCount!: number;
}

export class ExpirationPeriodsQueryModel {
	cardNumber!: string;
	entitlementCodeId!: number;
	headquarterId!: number;
	entitlementTypeId!: number;
}

export class BalanceExpirationDateModel {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;

	@JsonProperty({ type: Date })
	startDate!: Date;

	@JsonProperty({ type: Date })
	expirationDate!: Date;
}

export class BalanceOverviewModel {
	entitlementType!: string;
	unitCount!: number;
	newBalance!: number;
	measurementUnit!: string;
	measurementUnitDescription!: string;
}

export class AddBalanceActionModel {
	action!: string;
	currentTabIndex!: number;
	overviewItem?: BalanceOverviewModel | null;
	saveItem?: OperatorBulkTransactionModel | null;
}

export class AddBalanceConfirmationModel {
	expirationPeriodId: number | null = null;
	expirationDate: Date | null = null;
	reasonId: number | null = null;
	notes: string | null = null;
	dateSelectionType!: DateSelectionInputType;
}

export enum DateSelectionInputType {
	Dropdown = 'Dropdown',
	Calendar = 'Calendar'
}