import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import 'semantic-ui/semantic';

import { environment } from '@nstep-public/environments/environment';
import { AppModule } from '@nstep-public/app/app.module';

if (environment.disableConsoleLogs) {
	window.console.log = function () {
		return;
	};

	window.console.info = function () {
		return;
	};

	window.console.debug = function () {
		return;
	};

	window.console.warn = function () {
		return;
	};

	window.console.group = function () {
		return;
	};

	window.console.groupCollapsed = function () {
		return;
	};

	window.console.groupEnd = function () {
		return;
	};
}

if (environment.target == 'production') {
	enableProdMode();
}

/*
 * This is here because when serializing dates, the default implemention modifies the returned result based on the client timezone
 * which is not a desired behavior especially when the input for the date has no-timezone ('Z') suffix specified (e.g. '2020-12-02T13:02.123')
 */
Date.prototype.toISOString = Date.prototype.toJSON = function (includeLocalTimezone = false) {
	const pad = function pad(num: number | string, size = 2) {
		num = num.toString();
		while (num.length < size) num = '0' + num;
		return num;
	};

	let str = `${this.getFullYear()}-${pad(this.getMonth() + 1)}-${pad(this.getDate())}`;
	str += `T${pad(this.getHours())}:${pad(this.getMinutes())}:${pad(this.getSeconds())}.${pad(this.getMilliseconds(), 3)}`;

	if (includeLocalTimezone) {
		const tzo = -this.getTimezoneOffset();
		const dif = tzo >= 0 ? '+' : '-';
		
		str += `${dif}${pad(Math.floor(Math.abs(tzo) / 60))}:${pad(Math.abs(tzo) % 60)}`;
	}

	return str;
};

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));
