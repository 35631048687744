<div class="ui divider"></div>

<div class="ui form">
	<h3 class="ui header" *ngIf="currentTabIndex === 2">1. Transactions</h3>
	<h3 class="ui header" *ngIf="currentTabIndex === 3">1. Overview</h3>
	<div class="field">
		<app-table-view
			[itemTemplate]="rationedTypesItemTemplate"
			[data]="tableData"
			[noSearch]="true"
			[dataReady]="tableDataReady"
			[columns]="tableColumns">
		</app-table-view>
	</div>
	<div class="fields" *ngIf="currentTabIndex === 2 || currentTabIndex === 3">
		<div class="field">
			<button class="ui negative basic button" (click)="cancel()">{{ currentTabIndex === 2 ? 'Cancel' : 'Return'}}</button>
		</div>
		<div class="field">
			<button class="ui positive basic button" (click)="next()">{{ currentTabIndex === 3 ? 'Confirm' : 'Next'}}</button>
		</div>
	</div>

	<form *ngIf="currentTabIndex === 4" (submit)="next()">
		<div class="field">
			<h4 class="ui header">Please fill in your AMIS Card PIN ({{attempts}}/3 attempts)</h4>
		</div>
		<div class="fields">
			<div class="field">
				<button class="ui negative basic button" (click)="cancel()">Cancel</button>
			</div>

			<input type="text" name="fakeUserName" autocomplete="off" style="display: none;">
			<div class="three wide field" app-field [error]="validation | path: 'pin'">
				<input type="text"
					style="-text-security:disc; -webkit-text-security:disc;"
					autoComplete="off"
					name="cardPin"
					[(ngModel)]="pinConfirmationModel.pin">
			</div>

			<div class="field">
				<button class="ui positive basic button" type="submit" [disabled]="!isValid">Confirm</button>
			</div>
		</div>
	</form>
</div>

<ng-template #rationedTypesItemTemplate let-item="item">
	<tr style="text-align: center;">
		<td>{{item.entitlementType}}</td>
		<td *ngIf="currentTabIndex === 2">
			<input style="max-width: 85px;"
				type="number"
				min="0"
				[max]="item.parsedBalance"
				[step]="item.minimumBuyAmount"
				[(ngModel)]="item.transactionAmount"
				[disabled]="item.parsedBalance <= 0"
				(blur)="setPurchaseAmount(item)">
		</td>
		<td *ngIf="currentTabIndex === 3 || currentTabIndex === 4">{{item.transactionAmount}}</td>
		<td>{{item.parsedBalance}}</td>
		<td>{{item.measurementUnit}}</td>
		<td>{{item.measurementUnitDescription}}</td>
	</tr>
</ng-template>