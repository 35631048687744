import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { GroupedTransactionsOnline } from '@nstep-public/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TransactionHistoryService {

	constructor(private apiService: ApiService) {
	}

	getUserTransactions(): Observable<GroupedTransactionsOnline[]> {
		return this.apiService.get([GroupedTransactionsOnline], 'transaction-history/grouped');
	}
}