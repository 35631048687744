import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { EntitlementTypeDto, UnrationedBalanceModel } from '@nstep-public/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PosOperatorService {

	constructor(private apiService: ApiService) {
	}

	getAmisCardEntitlements(amisCardNumber: string): Observable<UnrationedBalanceModel> {
		return this.apiService.get(UnrationedBalanceModel, `pos-operator/${amisCardNumber}`);
	}

	getTypes(ids: number[]): Observable<EntitlementTypeDto[]> {
		return this.apiService.post([EntitlementTypeDto], 'pos-operator/entitlement-types', ids);
	}
}
