import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';

import { environment } from '@nstep-public/environments/environment';
import { AppComponent } from '@nstep-public/app/app.component';
import { routes } from '@nstep-public/app/app.routes';

import { AuthInterceptor, CoreModule, GlobalErrorHandler, UpdateDateHttpInterceptor } from '@nstep-common/core';
import { SemanticUiModule } from '@nstep-common/semantic-ui';
import { Enviroment } from '@nstep-common/utils';
import { AmisCardSearchComponent, CancelTransactionComponent, LayoutComponent } from '@nstep-public/shared';

import {
	LoginComponent,
	AddBalanceComponent,
	BalanceComponent,
	ConfirmationComponent,
	EntitlementCheckComponent,
	NonRationedTypesComponent,
	OnlineTransactionsComponent,
	RationedTypesComponent,
	RationedTypesPickComponent,
	ReportsComponent,
	SelectLocationComponent,
	TransactionHistoryComponent
} from '@nstep-public/pages';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		LayoutComponent,
		BalanceComponent,
		ReportsComponent,
		TransactionHistoryComponent,
		EntitlementCheckComponent,
		SelectLocationComponent,
		OnlineTransactionsComponent,
		NonRationedTypesComponent,
		RationedTypesComponent,
		AmisCardSearchComponent,
		AddBalanceComponent,
		RationedTypesPickComponent,
		ConfirmationComponent,
		CancelTransactionComponent
	],
	imports: [
		FormsModule,
		CommonModule,
		BrowserModule,
		CoreModule,
		SemanticUiModule,
		RouterModule.forRoot(routes),
		JwtModule.forRoot({
			config: {
				tokenGetter: () => {
					return localStorage.getItem('accessToken');
				}
			}
		})
	],
	providers: [
		{
			provide: APP_BASE_HREF,
			useValue: environment.angularRouterBaseHref
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UpdateDateHttpInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: Enviroment,
			useValue: environment
		},
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
